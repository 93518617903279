// Calendar customization
.rbc-calendar {
  .rbc-toolbar {
    margin-bottom: 1rem;
    
    .rbc-toolbar-label {
      font-size: 1.25rem;
      font-weight: 500;
    }

    button {
      color: #212529;
      background-color: #fff;
      border: 1px solid #ced4da;
      padding: 0.375rem 0.75rem;
      border-radius: 0.25rem;
      
      &:hover {
        background-color: #f8f9fa;
        border-color: #ddd;
      }
      
      &.rbc-active {
        background-color: #007bff;
        border-color: #007bff;
        color: #fff;
      }
    }
  }

  .rbc-month-view {
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    
    .rbc-header {
      padding: 0.75rem;
      font-weight: 500;
      border-bottom: 1px solid #ddd;
    }
    
    .rbc-day-bg {
      &.rbc-today {
        background-color: #f8f9fa;
      }
    }
  }

  .calendar-event {
    background-color: #007bff;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 0.85rem;
    margin: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    
    &:hover {
      background-color: darken(#007bff, 10%);
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .rbc-calendar {
    .rbc-toolbar {
      flex-direction: column;
      align-items: stretch;
      gap: 0.5rem;
      
      .rbc-toolbar-label {
        text-align: center;
      }
      
      .rbc-btn-group {
        justify-content: center;
      }
    }
    
    .rbc-month-view {
      .rbc-header {
        padding: 0.5rem;
        font-size: 0.9rem;
      }
      
      .calendar-event {
        font-size: 0.75rem;
        padding: 1px 3px;
      }
    }
  }
}