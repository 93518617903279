.blink {
    animation: blink 2s steps(5, start) infinite;
    -webkit-animation: blink 1s steps(5, start) infinite;
  }
  @keyframes blink {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink {
    to {
      visibility: hidden;
    }
  }

  @keyframes fa-blink {
    0% { opacity: 0; }
    25% { opacity: 0.45; }
    40% { opacity: 0.85; }
    50% { opacity: 0.99; }    
    75% { opacity: 0.85; }    
    75% { opacity: 0.45; }    
    100% { opacity: 0; }
}
.fa-blink {
  -webkit-animation: fa-blink 2s linear infinite;
  -moz-animation: fa-blink 2s linear infinite;
  -ms-animation: fa-blink 2s linear infinite;
  -o-animation: fa-blink 2s linear infinite;
  animation: fa-blink 2s linear infinite;
}